import axios from "axios";
import {
  keyAccessToken,
  keyUserId,
} from "../../../../../assets/js/SessionStorageNames";

const sendMlProd = (
  productId,
  title,
  price,
  body,
  condition,
  pausePublication,
  imagesPlus,
  aditionalsParams,
  warranty_type,
  warranty_value,
  entId,
  categoryMl,
  listingType,
  officialStoreId,
  publishGroup
) => {
  console.log("aditionas params", aditionalsParams);
  return axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/v1/ml-product/ml-post-one-product-from-odoo`,
    {
      dataProd: {
        allowLog: true,
        productId: productId,
        title: title,
        body: body,
        condition: condition,
        imagesPlus: imagesPlus,
        pausePublication: pausePublication,
        aditionalsParams: {
          brand: aditionalsParams.brand,
          model: aditionalsParams.model,
          width: aditionalsParams.width,
          height: aditionalsParams.height,
          length_prod: aditionalsParams.length_prod,
          weight: aditionalsParams.weight,
        },
        ...(warranty_type && { warranty_type: warranty_type }),
        ...(warranty_value && { warranty_days: warranty_value }),
      },
      entId: entId,
      categoryMl: categoryMl,
      listingType: listingType,
      officialStoreId: officialStoreId,
      publishGroup,
      // publishGroup: publishGroup === 'groupd' ? true : false,
    },
    {
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
        "x-access-token": localStorage.getItem(keyAccessToken),
        "x-user-id": localStorage.getItem(keyUserId),
      },
    }
  );
};

const postOneOdooMl = async (
  entId,
  paramsToSend,
  categoryMl,
  listingType,
  officialStoreId,
  publishGroup
) => {
  let datagood = [];
  let databad = [];
  await Promise.all(
    paramsToSend.map(async (v) => {
      console.log("antes de enviar Funcion postOneOdooMl", v);
      try {
        const aditionalsParams = {
          brand: v.brand,
          model: v.model,
          width: v.width,
          height: v.height,
          length_prod: v.length_prod,
          weight: v.weight,
        };
        const send = await sendMlProd(
          v.productId,
          v.title,
          v.price,
          v.body,
          v.condition,
          v.pausePublication,
          v.imagesPlus,
          aditionalsParams,
          v.warranty_type,
          v.warranty_value,
          entId,
          categoryMl,
          listingType,
          officialStoreId,
          publishGroup
        );
        console.log("respuesta ++++++++++", send.data.data);
        const data = send.data.data;
        if (data.good.length > 0) {
          data.good.map((v) => datagood.push(v));
        }
        if (data.errors.length > 0) {
          data.errors.map((v) => databad.push(v));
        }
      } catch (error) {
        console.log("el valor error", error);
        databad.push(error);
      }
    })
  );
  return { datagood, databad };
};

export default postOneOdooMl;
