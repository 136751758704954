import React, { useState, useEffect, useContext } from 'react'
import NavbarAdminMenuStyle from '../../../assets/css/components/admin/menus/NavbarAdminMenuStyle'
import { BiBell, BiBug, BiCloudRain, BiCodeBlock, BiCodeCurly, BiFile, BiImageAlt, BiMenu, BiPowerOff, BiUserX, BiLineChart, BiRocket } from "react-icons/bi";
import { GlobalContext } from "../../../contexts/GlobalContext";
import { Redirect } from "react-router-dom";
import axios from "axios";
import {
    keyUserId,
    keyAccessToken,
    keyUserRoles,
    userEmail,
    third
} from "../../../assets/js/SessionStorageNames";
import { Link } from "react-router-dom";
import SelectEnterprise from './components/SelectEnterprise';
import Notifications from '../notifications/Notifications';

export default function NavbarAdmin(props) {

    const globalContext = useContext(GlobalContext);
    const [redirect, setRedirect] = useState(null);
    const [nameUser, setNameUser] = useState(null);
    const [userRoles, setUserRoles] = useState(null);
    const [userEmailVali, setUserEmailVali] = useState(null)
    const [showOptions, setShowOptions] = useState(true);
    const [isThirdPartyUser, setIsThirdPartyUser] = useState(globalContext.third);

    function verifyThird() {
        if (globalContext.third === null) {
            if (localStorage.getItem(third)) {
                globalContext.setThird(JSON.parse(localStorage.getItem(third)));
            } else {
                globalContext.setThird(false);
            }
        }
        // console.log("third: ",globalContext.third);
        setIsThirdPartyUser(globalContext.third);
    }

    useEffect(() => {
        verifyThird();
        disableOptions()
        const v_keyUserRoles = localStorage.getItem(keyUserRoles);
        const v_userEmail = localStorage.getItem(userEmail);
        const v_keyAccessToken = localStorage.getItem(keyAccessToken);
        const v_keyUserId = localStorage.getItem(keyUserId);

        if (!v_keyUserRoles || !v_userEmail || !v_keyAccessToken || !v_keyUserId) {
            // setRedirect(<Redirect to="/login" />);
            globalContext.setVerifyLogin(true);
        } else {
            globalContext.setVerifyLogin(false);
            setUserRoles(v_keyUserRoles.split(",").filter(String));
            setUserEmailVali(v_userEmail);

        }
        //eslint-disable-next-line
    }, [localStorage.getItem(keyUserRoles), localStorage.getItem(userEmail), localStorage.getItem(keyAccessToken), localStorage.getItem(keyUserId), globalContext.third]);

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_BACKEND_URL}/v1/profile/my`, {
                headers: {
                    "x-api-key": process.env.REACT_APP_API_KEY,
                    "x-access-token": localStorage.getItem(keyAccessToken),
                    "x-user-id": localStorage.getItem(keyUserId),
                },
            })
            .then((res) => {
                setNameUser(res.data.data.name);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [nameUser]);

    const logOut = () => {
        console.log({
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        });
        globalContext.setLoaderPage(true);
        axios
            .delete(`${process.env.REACT_APP_BACKEND_URL}/v1/logout`, {
                headers: {
                    "x-api-key": process.env.REACT_APP_API_KEY,
                    "x-access-token": localStorage.getItem(keyAccessToken),
                    "x-user-id": localStorage.getItem(keyUserId),
                },
            })
            .then(() => {
                globalContext.setThird(undefined);
                setRedirect(<Redirect to="/login" />);
            })
            .catch((err) => {
                console.error(err,err)
                globalContext.setLoaderPage(false);
                globalContext.showModalWithMsg('Presentamos errores de comunicación, por favor cierre la pestaña y abra otra.');
            }).finally(() => {
                globalContext.setLoaderPage(false);
                window.localStorage.clear();
                localStorage.clear();
            });
    };


    const showSidebarNotifications = (e) => {
        e.preventDefault();
        globalContext.setShowNotifications()
    }

    async function disableOptions() {
        if(userRoles?.includes("SCANNER")) {
            setShowOptions(false);
        }
    };
    return (
        <NavbarAdminMenuStyle.ContNavBarAdmin>
            {redirect}
            <NavbarAdminMenuStyle.NavBarAdmin>
                {showOptions && 
                <NavbarAdminMenuStyle.ListIcons>
                    <NavbarAdminMenuStyle.ItemListIcon>
                        <NavbarAdminMenuStyle.BtnIconList onClick={() => globalContext.setStatusMenu(true)}>
                            <BiMenu />
                        </NavbarAdminMenuStyle.BtnIconList>
                    </NavbarAdminMenuStyle.ItemListIcon>
                </NavbarAdminMenuStyle.ListIcons>
                }
                <SelectEnterprise />
                <NavbarAdminMenuStyle.ListIcons>
                { isThirdPartyUser !== null &&
                    <>
                    {showOptions && 
                        <>
                            { !isThirdPartyUser && 
                            <>
                            <NavbarAdminMenuStyle.ItemListIcon>
                                <NavbarAdminMenuStyle.BtnIconList onClick={() => globalContext.setModalReporPostProducts(true)}>
                                    <BiLineChart />
                                </NavbarAdminMenuStyle.BtnIconList>
                            </NavbarAdminMenuStyle.ItemListIcon>
                            <NavbarAdminMenuStyle.ItemListIcon>
                                <NavbarAdminMenuStyle.BtnIconList onClick={() => alert('add somenthing')}>
                                    <BiRocket />
                                </NavbarAdminMenuStyle.BtnIconList>
                            </NavbarAdminMenuStyle.ItemListIcon>
                            
                            <NavbarAdminMenuStyle.ItemListIcon>
                                <NavbarAdminMenuStyle.BtnIconList onClick={(e) => showSidebarNotifications(e)}>
                                    <BiBell />
                                </NavbarAdminMenuStyle.BtnIconList>
                            </NavbarAdminMenuStyle.ItemListIcon>
                            </>
                            }
                            
                            {
                                userRoles && userRoles.map((e, k) => {
                                    if (
                                        e === "ADMIN_ENTERPRISE" || userEmailVali === 'jesmir.f@212global.com' || 
                                        userEmailVali === 'windaly.z@licoresfactory.com' || userEmailVali === 'erick.m@licoresfactory.com' 
                                        || userEmailVali === 'barbara.o@212global.com' || userEmailVali === 'rudy.v@212global.com') {
                                        return (
                                            <Link to="/admin/user-config/user-block-grid" key={k}>
                                                <NavbarAdminMenuStyle.ItemListIcon>
                                                    <NavbarAdminMenuStyle.BtnIconList>
                                                        <BiUserX />
                                                    </NavbarAdminMenuStyle.BtnIconList>
                                                </NavbarAdminMenuStyle.ItemListIcon>
                                            </Link>
                                        );
                                    }
                                })
                            }
                            <NavbarAdminMenuStyle.ItemListIcon>
                                <NavbarAdminMenuStyle.BtnIconList onClick={() => globalContext.setActiveModalProccess(true)}>
                                    <BiCloudRain />
                                </NavbarAdminMenuStyle.BtnIconList>
                            </NavbarAdminMenuStyle.ItemListIcon>

                            { !isThirdPartyUser && 
                                <>
                                    <NavbarAdminMenuStyle.ItemListIcon>
                                        <NavbarAdminMenuStyle.BtnIconList onClick={() => globalContext.setModalSelectDefaultImg(true)}>
                                            <BiImageAlt />
                                        </NavbarAdminMenuStyle.BtnIconList>
                                    </NavbarAdminMenuStyle.ItemListIcon>

                                    <Link to="/admin/debug-screen">
                                        <NavbarAdminMenuStyle.ItemListIcon>
                                            <NavbarAdminMenuStyle.BtnIconList>
                                                <BiBug />
                                            </NavbarAdminMenuStyle.BtnIconList>
                                        </NavbarAdminMenuStyle.ItemListIcon>
                                    </Link>

                                    {[
                                        'kronos.sytes.net:3000',
                                        'ragnarok.sytes.net:3000',
                                        'www.e-kommers.com',
                                    ].includes(window.location.host) && (<>
                                        <Link to="/admin/testing/admin-enterprise">
                                            <NavbarAdminMenuStyle.ItemListIcon>
                                                <NavbarAdminMenuStyle.BtnIconList>
                                                    <BiCodeBlock />
                                                </NavbarAdminMenuStyle.BtnIconList>
                                            </NavbarAdminMenuStyle.ItemListIcon>
                                        </Link>
                                    </>)}
                                    {[
                                        'kronos.sytes.net:3000',
                                        'ragnarok.sytes.net:3000',
                                        'www.e-kommers.com',
                                        'e-kommers.com'
                                    ].includes(window.location.host) && (<>
                                        <Link to="/admin/testing/admin">
                                            <NavbarAdminMenuStyle.ItemListIcon>
                                                <NavbarAdminMenuStyle.BtnIconList>
                                                    <BiCodeCurly />
                                                </NavbarAdminMenuStyle.BtnIconList>
                                            </NavbarAdminMenuStyle.ItemListIcon>
                                        </Link>
                                    </>)}

                                    <Link to="/admin/testing/xlsx">
                                        <NavbarAdminMenuStyle.ItemListIcon>
                                            <NavbarAdminMenuStyle.BtnIconList>
                                                <BiFile />
                                            </NavbarAdminMenuStyle.BtnIconList>
                                        </NavbarAdminMenuStyle.ItemListIcon>
                                    </Link>
                            </>
                        }
                        </>
                    }
                    <NavbarAdminMenuStyle.ItemListIcon>
                        <NavbarAdminMenuStyle.BtnIconList onClick={() => logOut()}>
                            <BiPowerOff />
                        </NavbarAdminMenuStyle.BtnIconList>
                    </NavbarAdminMenuStyle.ItemListIcon>
                    </>
                }
                </NavbarAdminMenuStyle.ListIcons>
            </NavbarAdminMenuStyle.NavBarAdmin>
            <Notifications />
        </NavbarAdminMenuStyle.ContNavBarAdmin>
    )
}
