import React, { useContext, useEffect, useState } from "react";
import {
  BtnSendProduct,
  ContFilterAndListGrid,
  ContGeneral,
  BtnSendProductClear,
  BtnAuthorize,
} from "../../../../../assets/css/components/admin/marketplace-to-marketplace/GridListProducts/GridListProducts";
import ContGrid from "../components/ContGrid";
import FiltersProducts from "../components/FiltersProducts";
import HeaderGrid from "../components/HeaderGrid";
import axios from "axios";
import getDataOdoo from "../functions/getDataOdoo";
import getDataMl from "../functions/getDataMl";
import { GlobalContext } from "../../../../../contexts/GlobalContext";
import getDataPy from "../functions/getDataPy";
import getProductsAnicam from "../functions/getProductsAnicam";
import getAnicamToken from "../functions/getAnicamToken";
import { keyAccessToken, keyUserId, keyUserRoles, tokenAnicam } from "../../../../../assets/js/SessionStorageNames";
import { getCategory } from "../functions/getCategoriesOdoo";
import { Col, Row, Spinner, Table, Form } from "react-bootstrap";
import { Button } from "../../../../../assets/css/components/admin/marketplace-to-marketplace/filters/FiltersStyles";
import ThirdPartyUsers from "../components/ThirdPartyUsers";
import verifyThirdPartyUser from "../../../../general/VerifyThirdPartyUser";
import PublishProduct from "../../../../../assets/css/components/admin/products/amazon/ModalPublishProduct";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import ConfigStyled from "../../../../../assets/css/components/admin/config/ConfigStyled";
export default function GridListProducts() {
  const [pluginSearch, setPluginSearch] = useState("odoo");
  const globalContext = useContext(GlobalContext);
  const pageItemCount = 20;
  const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
  const [currentPlugin, setCurrentPlugin] = useState("");
  const [mainPlugin, setMainPlugin] = useState("");
  const [products, setProducts] = useState([]);
  const [totalProducts, setTotalProducts] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [redirect, setRedirect] = useState(null);
  const [numRefresh, setNumRefresh] = useState(0);
  const [filter, setFilter] = useState("");
  const [filterLinkedTo, setFilterLinkedTo] = useState("");
  const [filterPrice, setFilterPrice] = useState("");
  const [filterStock, setFilterStock] = useState("");
  const [filterWithCompany, setFilterWithCompany] = useState(null);
  const [filterWithTaxes, setFilterWithTaxes] = useState(null);
  const [currentBrand, setCurrentBrand] = useState("");
  const [brands, setBrands] = useState([]);
  const [itemsChangeStore, setItemsChangeStore] = useState([]);
  const [load, setLoad] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showCategory, setShowCategory] = useState(false);
  const [currentCategory, setCurrentCategory] = useState({});
  const [selectedProds, setSelectedProds] = useState(false);
  const [modal, setModal] = useState(false);
  const [thirdUsers, setThirdUsers] = useState([])
  const [thirdUsersSelected, setThirdUsersSelected] = useState([])
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentThirdPartyUser, setCurrentThirdPartyUser] = useState({});
  const [finderTitle, setFinderTitle] = useState('');
  const [finderType, setFinderType] = useState(0);
  const [authorization,setAuthorization] = useState(true);
  const [currentStep, setCurrentStep] = useState(0);
  const [variants, setVariants] = useState([]);
  const [order, setOrder] = useState({});
  const [entsList, setEntsList] = useState([]);
  const [showedEnts, setShowedEnts] = useState([]);
  const [entSearch, setEntSearch] = useState("");
  const [selectedEnts, setSelectedEnts] = useState([])

  /**
     * function to get the enterprises from odoo
     * @returns 
     */
  async function getEnterprises() {
    const entId = globalContext.currentEnterpriseId;
    if (!entId) return;
    
    setLoading(true);

    await axios
        .get(
            `${process.env.REACT_APP_BACKEND_URL}/v1/enterprise`,
            {
                cancelToken: axiosCancelTokenSource.token,
                headers: {
                    "x-api-key": process.env.REACT_APP_API_KEY,
                    "x-access-token": localStorage.getItem(keyAccessToken),
                    "x-user-id": localStorage.getItem(keyUserId),
                },
                params: {
                  pageNumber: currentPage,
                  pageItemCount: 1000,
                  filter: { parentId: {$exists: true} },
              },
            }
        )
        .then(({ data: { data: { enterprises } } }) => {
            setEntsList(enterprises.map(v => ({id: v._id, name: v.name}) ));
            setShowedEnts(enterprises.map(v => ({id: v._id, name: v.name}) ));
        })
        .catch((err) => {
            console.error("err: ",err)
            handleAxiosError(
                err,
                "Disculpe, no se pudo consultar las marcas."
            );
        }).finally(()=>{
            setLoading(false);
        });
  }

  async function searchEntHandler(text) {
    const regex = new RegExp(text, "i");
    const resultado = entsList.filter((elemento) => regex.test(elemento));
    setShowedEnts(resultado);
    setEntSearch(text);
  }

  // function to select or deselect brands
  async function handleEnt(e) {
    const { value } = e.target;
    const indexVal = selectedEnts.indexOf(value);
    const entsSelected = indexVal === -1 ? [...selectedEnts, value] : selectedEnts.slice(0, indexVal).concat(selectedEnts.slice(indexVal + 1));
    await setSelectedEnts(entsSelected);
  }

  function findObjectContainingString(array, searchStrings) {
    return array.filter(element => {
      return searchStrings.some(str => {
        return typeof element === 'object'
          ? Object.values(element).includes(str)
          : element.includes(str);
      });
    });
  
  }

  const shippingValues = [
    {id: 184481, title: "Servicio de Entrega Zona 1 Moto (Gratis, hasta 72 horas"},
    {id: 158958, title: "Servicio de Flete Ventas - Retiro en Tienda"}
  ];
  const regexAddress = /^[0-9A-Za-zÁÉÍÓÚñáéíóúÑ\-_'\.\,\s]+$/;

  function getThirdPartyUsers() {
    const entId = globalContext.currentEnterpriseId;
    if (!entId) return;
    axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/v1/user/third/`, {
            cancelToken: axiosCancelTokenSource.token,
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
            },
            params: {
                entId: entId,
                pageNumber: currentPage,
                pageItemCount: 5,
                // filter: { name: userFilter, email: userFilter },
            },
        })
        .then((resp) => {
            const data = resp.data.data;
            setTotalRecords(data.totalUsers);
            setThirdUsers(data.users);
        })
        .catch((err) => {
            if (axios.isCancel(err)) return; //Don't show axios cancel error
            globalContext.showModalWithMsg(
                "Disculpe, hubo un error al intentar cargar la lista de usuarios."
            );
            console.error(err, err.response);
        });
  };

  function validateAdminUser() {
    if(localStorage.getItem(keyUserRoles).includes("ADMIN_ENTERPRISE")) return true;
  }

  /**
   * Function to auth third party users to use products
   * sending enterprise id, selected products array and selected third party users array
   * @returns 
   */
  async function authThirdProduct() {
    const entId = globalContext.currentEnterpriseId;
    const products = globalContext.listProductsToSend;

    const ids = currentThirdPartyUser._id ? [currentThirdPartyUser._id] : findObjectContainingString(entsList, selectedEnts).map(v=>v.id);

    if (!entId || (ids.length <= 0) || products.length <=0) return;
    const url = `${process.env.REACT_APP_BACKEND_URL}/v1/product/third`;
    const data = {
      entId,
      entIds: ids,
      products: products.map(v => v._id),
    };
    console.log("data: ",data);
    axios.post(url,
        data,
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
          },
        })
        .then((resp) => {
            globalContext.setListProductsToSend([]);
            globalContext.showModalWithMsg(
              "Autorización realizada correctamente."
            );
            setThirdUsersSelected([]);
            getProductsList();
            setModal(false);
        })
        .catch((err) => {
            if (axios.isCancel(err)) return; //Don't show axios cancel error

            globalContext.showModalWithMsg(
                "Disculpe, hubo un error al intentar autorizar a los usuarios."
            );
            console.error(err, err.response);
        });
  }

  function addToUsersList({target}) {
    let {checked, value: userid} = target;
    let terceros = thirdUsersSelected;
    if(checked) {
      terceros.push(userid);
    } else {
      terceros = terceros.filter(id => id !== userid);
    }
    setThirdUsersSelected(terceros);
  }

  function closeModal() {
    globalContext.setListProductsToSend([])
    setCurrentStep(0);
    setVariants([]);
    setModal(false);
    setThirdUsersSelected([]);
    setOrder({});
  }
  function openModal(type=false) {
    setAuthorization(type);
    if(type===true) {
      getThirdPartyUsers();
      getEnterprises()
    } else {
      searchVariants();
    }
    setModal(true);
  };

  function fshowCat(type=0) {
    setShowCategory(!showCategory);
    setFinderTitle(type===0 ? 'Categorías' : 'Terceros');
    setFinderType(type)
  }

  function findByThatCat(cat) {
    const entId = globalContext.currentEnterpriseId;
    if (!entId) return;
    getCategory(cat, entId, axiosCancelTokenSource, pluginSearch)
      .then(({data: {data }}) => {
        const { cat } = data;
        setCurrentCategory(cat);
      })
      .catch((err) => {
        console.error("err", err.message);
      });
  }

  const handleAxiosError = (err, msg = null, consoleLog = true) => {
    if (axios.isCancel(err)) return; //Don't show axios cancel error
    if (msg) globalContext.showModalWithMsg(msg);
    if (consoleLog) console.error("Error: ", err, "Response: ", err.response);
  };

  const getTokenAnicam = () => {
    getAnicamToken()
      .then((res) => {
        const data = res.data.data.access_token;
        localStorage.setItem(tokenAnicam, data);
        console.log("get token success");
      })
      .catch((err) => {
        console.error("err", err.message);
      });
  };

  function getProductsList() {
    setLoad(true);
    const entId = globalContext.currentEnterpriseId;
    if (!entId) return;

    if (pluginSearch === "") return;

    switch (pluginSearch) {
      case "odoo":
        getDataOdoo(
          entId,
          currentPage,
          pageItemCount,
          filter,
          filterLinkedTo,
          filterPrice,
          filterStock,
          filterWithCompany,
          filterWithTaxes,
          axiosCancelTokenSource,
          currentCategory
        )
          .then((res) => {
            setLoad(false);
            const data = res.data.data;
            //console.log('la data odoo', data)
            setTotalProducts(data.totalProducts);
            //hacer el setDataProduct
            // console.log("print table");
            // console.table(data.products);
            setProducts(
              data.products.map((prod) => {
                const variBarCodeOrig = prod.variants
                  .map((v) => v.attrib.barCode)
                  .filter((b) => b !== undefined);
                const variBarCode = variBarCodeOrig.filter(
                  (b, bIdx) => variBarCodeOrig.indexOf(b) === bIdx
                );
                const variSkuOrig = prod.variants
                  .map((v) => v.attrib.ref_int)
                  .filter((s) => s !== undefined);
                const variSku = variSkuOrig.filter(
                  (s, sIdx) => variSkuOrig.indexOf(s) === sIdx
                );
                const isPublicOnPeya =
                  prod.variants.length > 0
                    ? prod.variants.find((v) =>
                        v.integrationCodePeya ? true : false
                      )
                    : prod.integrationCodePeya
                    ? true
                    : false;
                return {
                  _id: prod._id,
                  title: prod.title,
                  odooId: prod.attrib.category_id,
                  price: new Intl.NumberFormat("de-DE").format(prod.price),
                  stock: prod.stock,
                  linked: prod.linkedTo && prod.linkedTo.length > 0,
                  isFullyLinked: prod.isFullyLinked,
                  isPartiallyLinked: prod.isPartiallyLinked,
                  thumbnail: [
                    prod.thumbnail &&
                    typeof prod.thumbnail == "object" &&
                    prod.thumbnail.url
                      ? prod.thumbnail.url
                      : prod.thumbnail && typeof prod.thumbnail == "string"
                      ? `data:image/png;base64,${prod.thumbnail}`
                      : false,
                  ],
                  barCode: prod.attrib.barCode,
                  height: prod.attrib.x_studio_product_pack_height,
                  lenght: prod.attrib.x_studio_product_pack_lenght,
                  width: prod.attrib.x_studio_product_pack_width,
                  weight: prod.attrib.weight,
                  variBarCode,
                  sku: prod.attrib.ref_int,
                  variSku,
                  hasTaxes: prod.hasTaxes,
                  hasVariants: prod.hasVariants,
                  isVariant: prod.isVariant,
                  peyaInfo: isPublicOnPeya,
                  description: prod.attrib.description
                    ? prod.attrib.description
                    : "",
                  brand: prod.attrib.x_studio_marca
                    ? prod.attrib.x_studio_marca
                    : "Sin asignar",
                    authorizeds: prod.authorizeds,
                };
              })
            );
          })
          .catch((err) => handleAxiosError(err, err));
        break; //End odoo

      case "ml":
        getDataMl(
          entId,
          currentPage,
          pageItemCount,
          filter,
          filterLinkedTo,
          currentBrand,
          filterStock,
          filterPrice,
          axiosCancelTokenSource
        )
          .then((res) => {
            setLoad(false);
            const data = res.data.data;
            setTotalProducts(data.totalProducts);
            setProducts(
              data.products.map((prod) => {
                const variBarCodeOrig = prod.variants
                  .map((v) => v.attrib.barCode)
                  .filter((b) => b !== undefined);
                const variBarCode = variBarCodeOrig.filter(
                  (b, bIdx) => variBarCodeOrig.indexOf(b) === bIdx
                );
                const variSkuOrig = prod.variants
                  .map((v) => v.attrib.sku)
                  .filter((s) => s !== undefined);
                const variSku = variSkuOrig.filter(
                  (s, sIdx) => variSkuOrig.indexOf(s) === sIdx
                );

                return {
                  _id: prod._id,
                  title: prod.title,
                  price: new Intl.NumberFormat("de-DE").format(prod.price),
                  stock: prod.stock,
                  linked: prod.linkedTo && prod.linkedTo.length > 0,
                  isFullyLinked: prod.isFullyLinked,
                  isPartiallyLinked: prod.isPartiallyLinked,
                  thumbnail: [prod.thumbnail],
                  officialStoreId: prod.attrib.official_store_id,
                  barCode: prod.attrib.barCode,
                  variBarCode,
                  sku: prod.attrib.sku,
                  variSku,
                  status: prod.attrib.status,
                };
              })
            );
          })
          .catch((err) => handleAxiosError(err, err));
        break; //End ml
      case "py":
        getDataPy(
          entId,
          currentPage,
          pageItemCount,
          filter,
          filterLinkedTo,
          currentBrand,
          filterPrice,
          filterStock,
          axiosCancelTokenSource
        )
          .then((res) => {
            const data = res.data.data;
            console.log(res);
            setTotalProducts(data.totalProducts);
            if (data.totalProducts === 0) {
              handleAxiosError(
                "No se encontro nada",
                "Disculpe, no tiene registrado ningun productos para Dafiti."
              );
              setLoad(false);
            } else {
              setLoad(false);
              setTotalProducts(data.total);
              setProducts(
                data.products.map((prod) => {
                  const variBarCodeOrig = prod.variants
                    .map((v) => v.attrib.barCode)
                    .filter((b) => b !== undefined);
                  const variBarCode = variBarCodeOrig.filter(
                    (b, bIdx) => variBarCodeOrig.indexOf(b) === bIdx
                  );
                  const variSkuOrig = prod.variants
                    .map((v) => v.attrib.sellerSku)
                    .filter((s) => s !== undefined);
                  const variSku = variSkuOrig.filter(
                    (s, sIdx) => variSkuOrig.indexOf(s) === sIdx
                  );

                  return {
                    _id: prod._id,
                    title: prod.title,
                    price: new Intl.NumberFormat("de-DE").format(prod.price),
                    stock: prod.stock,
                    linked: prod.linkedTo && prod.linkedTo.length > 0,
                    isFullyLinked: prod.isFullyLinked,
                    isPartiallyLinked: prod.isPartiallyLinked,
                    thumbnail: [
                      prod.thumbnail &&
                      typeof prod.thumbnail == "object" &&
                      prod.thumbnail.url
                        ? prod.thumbnail.url
                        : prod.thumbnail && typeof prod.thumbnail == "string"
                        ? `data:image/png;base64,${prod.thumbnail}`
                        : false,
                    ],
                    officialStoreId: prod.attrib.official_store_id,
                    barCode: prod.attrib.barCode,
                    variBarCode,
                    sku: prod.attrib.ref_int,
                    variSku,
                    status: prod.attrib.status,
                    peyaInfo: prod.integrationCodePeya,
                  };
                })
              );
            }
          })
          .catch((err) => handleAxiosError(err, err));
        break; //End peya
      case "anicam":
        getTokenAnicam();
        getProductsAnicam(entId, currentPage, pageItemCount, filter)
          .then((res) => {
            setLoad(false);
            const data = res.data.data;
            console.log("lo de anicam", data);
            setTotalProducts(data.total);
            setProducts(
              data.products.map((prod) => {
                return {
                  _id: prod._id,
                  title: prod.title,
                  price: new Intl.NumberFormat("de-DE").format(prod.high_price),
                  stock: prod.quantity,
                  linked: [],
                  isFullyLinked: false,
                  isPartiallyLinked: false,
                  thumbnail: [prod.large_image],
                  barCode: prod.code,
                  sku: prod.code,
                  status: true,
                  brand: prod.brand,
                  ...(prod.publishedOnMl && { ml: prod.publishedOnMl }),
                };
              })
            );
          })
          .catch((err) => {
            setLoad(false);
            handleAxiosError(err, err);
          });
        break;
      default:
        break;
    }
  }

  function validateSet({name, value}) {
    
    if(value === '') return '';
    let output = '';
    
    // validar numero de identificacion
    if(name === 'vat') {
      for(let i = 0; i < value.length; i++) {
        let char = value[i].toUpperCase();
        if(i === 0) {
          if(!/^[VE]/.test(char)) {
            char = 'V'+(!isNaN(char) ? char : '');
          } 
        } else if(!/[0-9]/.test(char)) {
          continue;
        }
        output += output.length <=9 ? char : '';
      }
    }

    if(name === 'shipping_type') {
      console.log("value", value);
      const match = shippingValues.find(item => item.id.toString() === value);
      console.log(match);
      if(match) {
        output = value
      }
    }
    
    // validar nombre
    if(name === 'name') {
      for(let i = 0; i < value.length; i++) {

        let char = value[i];
    
        // Permitir letras, espacios y apóstrofes
        if(/^[A-Za-zÁÉÍÓÚñáéíóúÑ'\s]$/.test(char)) {
          output += output.length <= 50 ? char : '';
        }
    
      }
    
    }

    // validadr numero de telefono
    if(name === 'phoneNumber' || name.includes('zip')) {
      for(let i = 0; i < value.length; i++) {
        let char = value[i];
        const regex = name === 'phoneNumber' ? /^[+]?[0-9]+$/ : /^[0-9]+$/;
        const limit = name === 'phoneNumber' ? 20 : 3;
        if(regex.test(char) || (name === 'phoneNumber' && output === "" && char === "+")) {
          output += output.length <= limit ? char : '';
        }
      }
    }

    // validar email
    if(name === 'email') {
      for(let i = 0; i < value.length; i++) {
        let char = value[i];
        // Permitir letras, guiones, guiones bajos, arroba y numeros
        if(/^[A-Za-z0-9.\-_@^=]+$/.test(char) && char !== "=") {
          output += output.length <=70 ? char : '';
        }
      }
    }

    // validar direcciones
    if(name.includes('street') || name.includes('city') || name.includes('state')) {
      for(let i = 0; i < value.length; i++) {
        let char = value[i];
        // Permitir numeros, letras, guiones, guiones bajos, puntos, comas y punto y comas
        if(regexAddress.test(char)) {
          output += char;
        }
      }
    }

    //  validar fecha
    if (name.includes("date")) {
      const fecha = new Date(value);
      output = !isNaN(fecha.getTime()) ? value : '';
    }
  
    return output;
  }
  
  function setOrderData(e) {
    const { value, name } = e.target;   
    const newData = {...order, [name]: validateSet({ value, name })}; 
    setOrder(newData);
  }

  async function fillShippingDirection(fill) {
    let address = {
      street: order.street || '',
      street2: order.street2 || '',
      city: order.city || '',
      state: order.state || '',
      zip: order.zip || '',
    };
    let shippingAddress = {};
    if(fill) {
      shippingAddress = await Object.fromEntries(
        Object.entries(address)
          .map(([key, value]) => [`shipping_${key}`, value])
          .filter(v => !!v || "")
          .filter(v => v[1].trim())
      );
    } else {
      shippingAddress = await Object.fromEntries(
        Object.entries(address)
          .map(([key, value]) => [key, value])
          .filter(v => !!v)
          .filter(v => v[1].trim())
      );
      const keys = await Object.keys(shippingAddress).filter(key => {
        return shippingAddress[key] === address[key];
      });
      const newAdd = keys.map((k) => {
        if (shippingAddress[k] === order[`shipping_${k}`]) {
          return k;
        }
      }).filter(v => !!v);
      shippingAddress = await Object.fromEntries(
        Object.entries(newAdd).map(([k,val]) => [`shipping_${val}`, ""])
      );
    }
    setOrder({...order, ...shippingAddress});
  }

  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <>
            <Row className="px-2 mt-2 d-block">
              <Row>
                <Col>
                  <Row>
                    <Col>
                      <h5>Productos</h5>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="pr-2 d-block">
                <Col>
                  <Row>
                    <Col>
                    {loading ? "Cargando..." :
                      <>
                        <Table striped bordered hover className="p-0 m-0">
                          <thead>
                            <tr>
                              <th colSpan={2}>Descripción</th>
                              <th style={{width: '150px'}}>Cantidad</th>
                            </tr>
                          </thead>
                        {globalContext.listProductsToSend.map((v, i)=> 
                            <tbody key={i}>
                              <tr 
                                /*onClick={(e)=> searchVariants(e)}*/
                                style={{
                                  backgroundColor: 
                                    variants.filter(d => d.baseProduct === v._id).length > 0 
                                      ? '' 
                                      : '#eb9d98'
                                }}
                              >
                                <td colSpan={3}>{v.title} - {variants.filter(d => d.baseProduct === v._id).length<1 ? 'Producto sin Stock' : ''}</td>
                                {/* <td width="200px">
                                </td> */}
                              </tr>
                              {variants.filter(d => d.baseProduct === v._id).map((a,k) => 
                                <tr key={k}>
                                  <td className="pt-4">{a.attrib?.ref_int}</td>
                                  <td className="pt-4">{a.title} - <strong>Disponible: {a.stock}</strong></td>
                                  <td style={{width: '150px'}}>
                                    <ConfigStyled.input
                                      className="my-3 me-1"
                                      // readOnly={isSending}
                                      type="number"
                                      min={0}
                                      max={a.stock}
                                      defaultValue={a.cantidad || 0}
                                      onChange={(e) => updateVariantQuantity(e.target.value, a._id, a.stock)}
                                    />
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          )}
                        </Table>
                      </>
                    }
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Row>
          </>
        );
      case 1:
        return (
          <>
          <Row>
            <Col xs={12} md={6}>
              <Row>
                <Col xs={12} className="mb-3">
                  <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size='12pt'>
                        Cliente
                    </ConfigStyled.text>
                    <Row>
                      <Col xs={12} className="mb-2">
                        <ConfigStyled.input
                          // readOnly={isSending}
                          className="text-capitalize"
                          placeholder='Nombre del Cliente'
                          name='name'
                          value={ order.name || "" }
                          onChange={e => setOrderData(e)}
                        />
                      </Col>
                      <Col xs={12} className="mb-2">
                        <ConfigStyled.input
                          // readOnly={isSending}
                          placeholder='Cédula de Identidad'
                          name='vat'
                          value={ order.vat || "" }
                          onChange={e => setOrderData(e)}
                        />
                      </Col>
                      <Col xs={12} className="mb-2">
                        <ConfigStyled.input
                          // readOnly={isSending}
                          placeholder='Número de Teléfono'
                          name='phoneNumber'
                          value={ order.phoneNumber || ""}
                          onChange={e => setOrderData(e)}
                        />
                      </Col>
                      <Col xs={12} className="mb-2">
                        <ConfigStyled.input
                          // readOnly={isSending}
                          placeholder='Correo Electrónico'
                          name='email'
                          value={ order.email || ""}
                          onChange={e => setOrderData(e)}
                        />
                      </Col>
                    </Row>
                  </ConfigStyled.ContInputAndLabel>
                </Col>

                <Col xs={12} className="mb-3">
                  <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size='12pt'>
                        Dirección del Cliente
                    </ConfigStyled.text>
                    <Row>
                      <Col xs={12} className="mb-2">
                        <ConfigStyled.input
                          // readOnly={isSending}
                          placeholder='Calle'
                          name='street'
                          value={order.street || ""}
                          onChange={e => setOrderData(e)}
                        />
                      </Col>
                      <Col xs={12} className="mb-2">
                        <ConfigStyled.input
                          // readOnly={isSending}
                          placeholder='Calle 2'
                          name='street2'
                          value={order.street2 || ""}
                          onChange={e => setOrderData(e)}
                        />
                      </Col>
                      <Col xs={12} lg={6} className="mb-2">
                        <ConfigStyled.input
                          // readOnly={isSending}
                          placeholder='Ciudad'
                          name='city'
                          value={order.city || ""}
                          onChange={e => setOrderData(e)}
                        />
                      </Col>
                      <Col xs={12} lg={6} className="mb-2">
                        <ConfigStyled.input
                          // readOnly={isSending}
                          placeholder='Estado'
                          name='state'
                          value={order.state || ""}
                          onChange={e => setOrderData(e)}
                        />
                      </Col>
                      <Col xs={12} lg={6} className="mb-2">
                        <ConfigStyled.input
                          // readOnly={isSending}
                          placeholder='Código Postal'
                          name='zip'
                          value={order.zip || ""}
                          onChange={e => setOrderData(e)}
                        />
                      </Col>
                    </Row>
                  </ConfigStyled.ContInputAndLabel>
                </Col>

              </Row>
              
            </Col>
            <Col>
              <Row>
                <Col xs={12} className="mb-3">
                  <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size='12pt'>
                      Dirección de Entrega
                    </ConfigStyled.text>
                    <Row>
                      <Col xs={12} className="mb-2">
                        <ConfigStyled.input
                            // readOnly={isSending}
                            placeholder='Calle'
                            name='shipping_street'
                            value ={order.shipping_street || ""}
                          onChange={e => setOrderData(e)}
                        />
                      </Col>
                      <Col xs={12} className="mb-2">
                        <ConfigStyled.input
                          // readOnly={isSending}
                          placeholder='Calle 2'
                          name='shipping_street2'
                          value={order.shipping_street2 || ""}
                          onChange={e => setOrderData(e)}
                        />
                      </Col>
                      <Col xs={12} lg={6} className="mb-2">
                        <ConfigStyled.input
                          // readOnly={isSending}
                          placeholder='Ciudad'
                          name='shipping_city'
                          value={order.shipping_city || ""}
                          onChange={e => setOrderData(e)}
                        />
                      </Col>
                      <Col xs={12} lg={6} className="mb-2">
                        <ConfigStyled.input
                          // readOnly={isSending}
                          placeholder='Estado'
                          name='shipping_state'
                          value={order.shipping_state || ""}
                          onChange={e => setOrderData(e)}
                        />
                      </Col>
                      <Col xs={12} lg={6} className="mb-2">
                        <ConfigStyled.input
                          // readOnly={isSending}
                          placeholder='Código Postal'
                          name='shipping_zip'
                          value={order.shipping_zip || ""}
                          onChange={e => setOrderData(e)}
                        />
                      </Col>
                      <Col xs={12} lg={6} className="mb-2 pt-2">
                        <ConfigStyled.text size='12pt'>
                          <input 
                            type="checkbox" 
                            onChange={(e) => fillShippingDirection(e.target.checked)}
                          />
                          {" "}
                          Dirección del Cliente
                        </ConfigStyled.text>
                      </Col>
                    </Row>
                  </ConfigStyled.ContInputAndLabel>
                </Col>

                <Col xs={12} className="mb-3">
                  <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size='12pt'>
                      Servicio de envío
                    </ConfigStyled.text>
                    <Row>
                      <Col xs={12} className="mb-2">
                        <ConfigStyled.select
                          name="shipping_type"
                          value={order.shipping_type || ""}
                          onChange={e => setOrderData(e)}
                        >
                            <option value="">Seleccione el tipo de envío</option>
                            {shippingValues.map(({id,title},k) => 
                              <option
                                key={k}
                                value={id}
                                title={title}
                              >
                                  {title}
                              </option>
                            )}
                        </ConfigStyled.select>
                      </Col>
                    </Row>
                  </ConfigStyled.ContInputAndLabel>
                </Col>
                
                {/* <Col xs={12} lg={6} className="mb-3">
                  <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size='12pt'>
                      Fecha de Expiración
                    </ConfigStyled.text>
                    <Row>
                      <Col xs={12} className="mb-2">
                        <ConfigStyled.input
                          // readOnly={isSending}
                          placeholder='Cédula de Identidad'
                          type="date"
                          name='exp_date'
                          value={order.exp_date || new Date().toISOString().substr(0, 10)}
                          onChange={e => setOrderData(e)}
                        />
                      </Col>
                    </Row>
                  </ConfigStyled.ContInputAndLabel>
                </Col>

                <Col xs={12} lg={6} className="mb-3">
                  <ConfigStyled.ContInputAndLabel>
                    <ConfigStyled.text size='12pt'>
                      Fecha de Presupuesto
                    </ConfigStyled.text>
                    <Row>
                      <Col xs={12} className="mb-2">
                        <ConfigStyled.input
                          // readOnly={isSending}
                          placeholder='Cédula de Identidad'
                          type="date"
                          name='budget_date'
                          value={order.budget_date || new Date().toISOString().substr(0, 10)}
                          onChange={e => setOrderData(e)}
                        />
                      </Col>
                    </Row>
                  </ConfigStyled.ContInputAndLabel>
                </Col> */}
              </Row>
            </Col>
          </Row>
        </>
      )
      default:
        break;
    }
  }

  function createOrder() {
    const orden = Object.keys(order).reduce((acc, key) => {
      if(order[key]) { 
        acc[key] = order[key];
      }
      return acc;
    }, {});
    console.log(orden);
    const { vat ,name, phoneNumber, zip, email, street, street2, city, state, shipping_street, shipping_street2, shipping_city, shipping_state, shipping_zip, shipping_type } = orden;
    const errors = [];

    const requiredFields = [
      {key: "name"},
      {key: "vat"},
      {key: "phoneNumber"},
      {key: "email"},
      {key: "street"},
      {key: "city"},
      {key: "state"},
      {key: "shipping_street"},
      {key: "shipping_city"},
      {key: "shipping_state"},
      {key: "shipping_type"}
    ];
    
    requiredFields.map(v => {
      const elements = document.getElementsByName(v.key);
      elements[0].classList.remove('border-danger');
    });

    if(!name || !vat || !phoneNumber || !email || !street || !city || !state || !shipping_street || !shipping_city || !shipping_state || !shipping_type) {
      if(!name) {
        errors.push({key: "name"});
      }
      if(!vat) {
        errors.push({key: "vat"});
      }
      if(!phoneNumber) {
        errors.push({key: "phoneNumber"});
      }
      if(!email) {
        errors.push({key: "email"});
      }
      if(!street) {
        errors.push({key: "street"});
      }
      if(!city) {
        errors.push({key: "city"});
      }
      if(!state) {
        errors.push({key: "state"});
      }
      if(!shipping_street) {
        errors.push({key: "shipping_street"});
      }
      if(!shipping_city) {
        errors.push({key: "shipping_city"});
      }
      if(!shipping_state) {
        errors.push({key: "shipping_state"});
      }
    } else {

      if(!/^[VE]{1}[0-9]{8,10}$/.test(vat)){
        errors.push({key: 'vat', msj: 'Debe ingresar una identificación con el formato correcto.'});
      }

      let nameParts = name.split(' ');
  
      // Debe ser demás de 2 caracteres y tener 2 o más partes (nombre y apellido)
      for(let i = 0; i < nameParts.length; i++) {
        if(nameParts[i].length < 3) {
            errors.push({key: 'name', msj: 'Cada nombre/apellido debe tener al menos 3 caracteres.'});
            break;
        }
      }

      if(nameParts.length < 2) {
        errors.push({key: 'name', msj: 'Debe ingresar un nombre y apellido.'});
      }
  
      const phoneRegex = /^[+]?[0-9]{8,20}$/;
      if(!phoneRegex.test(phoneNumber)) {
        errors.push({key: 'phoneNumber', msj: 'Debe ingresar el numero de telefono con el formato correcto.'});
      }

      const zipRegex =/^[0-9]{4}$/;

      if (zip && !zipRegex.test(zip)) {
        errors.push({key: 'zip', msj: 'Debe ingresar el numero de código zip con el formato correcto.'});
      }
      if (shipping_zip && !zipRegex.test(shipping_zip)) {
        errors.push({key: 'shipping_zip', msj: 'Debe ingresar el numero de código zip del envío con el formato correcto.'});
      }

      if(!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)){
        errors.push({key: 'email', msj: 'Debe ingresar el correo electrónico con el formato correcto.'});
      }

      if(!regexAddress.test(street)){
        errors.push({key: 'street', msj: 'Debe ingresar la direccion de calle con el formato correcto.'});
      }

      if(street2 && !regexAddress.test(street2)){
        errors.push({key: 'street2', msj: 'Debe ingresar la direccion de la segunda calle con el formato correcto.'});
      }

      if(!regexAddress.test(city)){
        errors.push({key: 'city', msj: 'Debe ingresar la ciudad con el formato correcto.'});
      }

      if(!regexAddress.test(state)){
        errors.push({key: 'state', msj: 'Debe ingresar el estado con el formato correcto.'});
      }

      if(!regexAddress.test(shipping_street)){
        errors.push({key: 'shipping_street', msj: 'Debe ingresar la direccion de calle de entrega con el formato correcto.'});
      }

      if(shipping_street2 && !regexAddress.test(shipping_street2)){
        errors.push({key: 'shipping_street2', msj: 'Debe ingresar la direccion de la segunda calle de entrega con el formato correcto.'});
      }

      if(!regexAddress.test(shipping_city)){
        errors.push({key: 'shipping_city', msj: 'Debe ingresar la ciudad de entrega con el formato correcto.'});
      }

      if(!regexAddress.test(shipping_state)){
        errors.push({key: 'shipping_state', msj: 'Debe ingresar el estado de entrega con el formato correcto.'});
      }

      const found = shippingValues.some(obj => obj[0] === shipping_type);

      if(shipping_type==="") {
        errors.push({key: 'shipping_type', msj: 'Debe seleccionar un tipo de envio valido.'});
      }

    }

    if(errors.length > 0) {
      console.error(errors);
      errors.map(v => {
        const elements = document.getElementsByName(v.key);
        elements[0].classList.add('border-danger');
      });
      globalContext.showModalWithMsg(
        "Complete de forma correcta los campos."
      );
      return ;
    } else {
      const entId = globalContext.currentEnterpriseId;
      const data = {
        entId,
        vat,
        name,
        phoneNumber,
        zip,
        email,
        street,
        street2,
        city,
        state,
        shipping_street,
        shipping_street2,
        shipping_city,
        shipping_state,
        shipping_zip,
        products: variants,
        shipping_type: parseInt(shipping_type)
      }

      const url = `${process.env.REACT_APP_BACKEND_URL}/v1/third/create-order`;
      axios.post(url,
        data,
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
          },
        })
        .then(async ({data: {data}}) => {
          if(data) {
            await globalContext.showModalWithMsg(
              `Exito al crear la orden.`
            );
            closeModal();
          }
        })
        .catch((err) => {
            if (axios.isCancel(err)) return; //Don't show axios cancel error
            globalContext.showModalWithMsg(
                `Disculpe, hubo un error al intentar crear la orden: ${err.response.data.message}.`
            );
            console.error(err, err.response);
        });
    }

  }

  function nextStepOrder(next) {
    if(!next && currentStep<=0) {
      closeModal();
      return;
    }

    if (currentStep >= 1 && next) {
      createOrder();
      return;
    }

    if (currentStep===0) {
      const groupedByBaseProduct = variants.reduce((acc, curr) => {
        if (!acc[curr.baseProduct]) {
          acc[curr.baseProduct] = []; 
        }
        acc[curr.baseProduct].push(curr);
        return acc;
      }, {});

      let isValid = true;

      Object.values(groupedByBaseProduct).forEach(group => {
        console.log(variants);
        const hasQuantity = group.some(variant => variant.cantidad > 0 && variant.cantidad <= variant.stock);
        if (!hasQuantity) {
          isValid = false;
        } 
      });

      if (!isValid || variants.length <= 0) {
        globalContext.showModalWithMsg(
          "Debe asignar una cantidad valida a cada producto seleccionado (Por lo menos uno entre las variantes y menor que el stock disponible)."
        );
        return;
      }
    }
    
    setCurrentStep(next ? currentStep +1 : currentStep -1);
  }

  function updateVariantQuantity(value, id, stock) {
    if(isNaN(value) || value < 0) {
      return;
    }

    const index = variants.findIndex(obj => obj._id === id);

    if (index !== -1) {
      variants[index].cantidad = parseInt(value);
    }

    setVariants(variants);

  }
  
  async function searchVariants() {
    setLoading(true);
    const entId = globalContext.currentEnterpriseId;
    const products = globalContext.listProductsToSend.map(v => v._id);
    
    if (!entId || products.length <=0) return;
    const url = `${process.env.REACT_APP_BACKEND_URL}/v1/odoo/products-variants`;
    const data = {
      entId,
      products,
    };
    
    await axios.post(url,
      data,
      {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          "x-access-token": localStorage.getItem(keyAccessToken),
          "x-user-id": localStorage.getItem(keyUserId),
        },
      })
      .then(({data: { data: { variants } }}) => {
        // console.log(variants);
        setLoading(false);
        setVariants(variants);
      })
      .catch((err) => {
        setLoading(false);
        if (axios.isCancel(err)) return; //Don't show axios cancel error

        globalContext.showModalWithMsg(
          "Disculpe, hubo un error al intentar obtener las variantes."
        );
        console.error(err, err.response);
      });
        
  }

  useEffect(() => {
    getProductsList();
  },[
    globalContext.currentEnterpriseId,
    pageItemCount,
    currentPlugin,
    currentPage,
    filter,
    filterLinkedTo,
    filterPrice,
    filterStock,
    filterWithCompany,
    filterWithTaxes,
    currentBrand,
    pluginSearch,
    currentCategory
  ]);
  return (
    <>
    <ContGeneral>
      <HeaderGrid />
      <ContFilterAndListGrid>
        <FiltersProducts
          setPluginSearch={setPluginSearch}
          pluginSearch={pluginSearch}
          setCurrentPage={setCurrentPage}
          total={totalProducts}
          currentPage={currentPage}
          load={load}
          pageItemCount={pageItemCount}
          setFilterLinkedTo={setFilterLinkedTo}
          setFilterPrice={setFilterPrice}
          setFilterStock={setFilterStock}
          setFilterWithCompany={setFilterWithCompany}
          setFilterWithTaxes={setFilterWithTaxes}
          filterLinkedTo={filterLinkedTo}
          filterPrice={filterPrice}
          filterStock={filterStock}
          filterWithCompany={filterWithCompany}
          filterWithTaxes={filterWithTaxes}
          setShowFinder={fshowCat}
          setCurrentCategory={setCurrentCategory}
          currentCategory={currentCategory}
          currentThirdPartyUser={currentThirdPartyUser}
          setCurrentThirdPartyUser={setCurrentThirdPartyUser}
          validateAdminUser={validateAdminUser}
          validateThird={verifyThirdPartyUser}
        />
        <ContGrid
          setFilter={setFilter}
          pluginSearch={pluginSearch}
          load={load}
          totalProducts={totalProducts}
          listProd={products}
          showCategory={showCategory}
          setShowCategory={fshowCat}
          setCurrentCategory={setCurrentCategory}
          findByThatCat={findByThatCat}
          selectedProds={selectedProds}
          finderTitle={finderTitle}
          finderType={finderType}
          currentThirdPartyUser={currentThirdPartyUser}
          setCurrentThirdPartyUser={setCurrentThirdPartyUser}
          validateThird={verifyThirdPartyUser}
        />
      </ContFilterAndListGrid>
      {globalContext.listProductsToSend.length > 0 && (
        <div /* className='fixed-bottom d-flex m-3'  */style={{ zIndex: 2, /* width: '300px' */ }}>
          {validateAdminUser() && 
            <BtnAuthorize
              onClick={() => {
                openModal(true);
                // console.log(globalContext.listProductsToSend);
              }}
            >
              Autorizar {globalContext.listProductsToSend.length}
            </BtnAuthorize>
          }
          
          {(verifyThirdPartyUser() || validateAdminUser()) && 
            <BtnAuthorize
              right={!verifyThirdPartyUser() && validateAdminUser() && '15px'}
              bottom={!verifyThirdPartyUser() && validateAdminUser() && '65px'}
              onClick={() => {
                openModal(false);
              }}
            >
              Agregar al Carrito {globalContext.listProductsToSend.length}
            </BtnAuthorize>
          }
          <BtnSendProduct
            onClick={() => {
              globalContext.setModalPostProduct(true);
            }}
          >
            Publicar {globalContext.listProductsToSend.length}
          </BtnSendProduct>
          <BtnSendProductClear
            onClick={() => {globalContext.setListProductsToSend([])}}
          >
            Limpiar
          </BtnSendProductClear>
        </div>
      )}
    </ContGeneral>

    <PublishProduct.ContModal d={modal ? 'flex' : 'none'}>
      <PublishProduct.ModalProduct>
        <PublishProduct.headerModal>
          <PublishProduct.btnCloseModal onClick={closeModal}>
            <FontAwesomeIcon icon={faTimes} />
          </PublishProduct.btnCloseModal>
          <PublishProduct.textHeader size='15pt' ml='0' mr='auto'>
            {authorization ? "Autorizar Productos a Terceros" : "Orden"}
          </PublishProduct.textHeader>
        </PublishProduct.headerModal>
          <PublishProduct.bodyModal>
            <Row className="m-2 d-block">
              {authorization ? 
                <Row>
                  <Col>
                    <Row>
                      <Col>
                        <h5>Empresas</h5>
                      </Col>
                    </Row>
                    <Row className="mr-2 d-block" style={{ maxHeight: '150px', overflow: 'auto'}}>
                      <Col>
                        <ConfigStyled.ContInputAndLabel>
                          <Form.Group
                              as={Col}
                              controlId='my_multiselect_field'
                          >
                              <Form.Control
                                  disabled={loading}
                                  type='text'
                                  placeholder='Buscar Marca'
                                  value={entSearch}
                                  onChange={(e) =>
                                      searchEntHandler(
                                          e.target.value
                                      )
                                  }
                              />
                              {!loading ? 
                                <Form.Control
                                    as='select'
                                    multiple
                                    onClick={e => handleEnt(e)}
                                    value={selectedEnts}
                                >
                                    {showedEnts.map((v) => (
                                        <option value={v._id} key={v._id}>
                                            {v.name}
                                        </option>
                                    ))}
                                </Form.Control>
                              : 
                                <div className="text-center">
                                  <Spinner animation='grow' size='lg' style={{ color: "#7367F0" }} />
                                </div>
                              }

                          </Form.Group>
                        </ConfigStyled.ContInputAndLabel>
                        {/* {currentThirdPartyUser._id ? 
                          <ThirdPartyUsers data={[currentThirdPartyUser]} disableFunctions={true}></ThirdPartyUsers>
                        : 
                          thirdUsers.length > 0 ? 
                             <ThirdPartyUsers data={thirdUsers} addToUsersList={addToUsersList}></ThirdPartyUsers>
                          : (
                            <div className="text-center">
                              <Spinner animation='grow' size='lg' style={{ color: "#7367F0" }} />
                            </div>
                          )
                        } */}
                      </Col>
                    </Row>
                    {findObjectContainingString(entsList, selectedEnts).map(v => v.name).join(", ")}

                    {/* {!currentThirdPartyUser._id && 
                      <Row>
                        <Col>
                          {totalRecords}
                        </Col>
                      </Row>
                    } */}
                  </Col>
                </Row>
              :
                renderStep()
              }
              <Row>
                <Col>
                  <hr />
                </Col>
              </Row>
              {authorization && 
                <Row className="mt-2 d-block">
                  <Col>
                    <Row>
                      <Col>
                        <h5>Productos</h5>
                      </Col>
                    </Row>
                  </Col>
                  <Row className="mr-2 d-block">
                    <Col>
                      <Row>
                        <Col>
                          <Table striped bordered>
                            <thead>
                              <tr>
                                <th width="150px">SKU</th>
                                <th>Descripción</th>
                              </tr>
                            </thead>
                          </Table>
                        </Col>
                      </Row>
                      <Row>
                        <Col style={{maxHeight: '150px', overflow: 'auto'}}>
                          <Table striped bordered hover>
                            <tbody>
                                {globalContext.listProductsToSend.map((v, i)=> 
                                  <tr key={i}>
                                    <td width="150px">{v.sku}</td>
                                    <td >{v.title}</td>
                                  </tr>
                                )}
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Row>
              }
            </Row>
            <Row className="m-2 h-100">
              <Col>
                <Row>
                  <Col md={4}>
                    <Button color="secondary" onClick={() => {authorization ? closeModal() : nextStepOrder(false)}}>
                      {authorization ?
                        'Cerrar'
                      : currentStep > 0 ? "Atras" : "Cerrar"
                      }
                    </Button>
                  </Col>
                  <Col md={{ span: 4, offset: 4 }} className="text-rigth">
                    <Button color="primary" onClick={() => {authorization ? authThirdProduct() : nextStepOrder(true)}}>
                      {authorization ?
                        'Asignar Productos'
                      : currentStep === 0 ? "Siguiente" : "Crear Orden"
                      }
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </PublishProduct.bodyModal>
      </PublishProduct.ModalProduct>
    </PublishProduct.ContModal>
  </>
  );
}
