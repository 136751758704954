import React from "react";
import PageContentStyle from "../../../assets/css/components/admin/menus/PageContent.css";
import NavbarAdmin from "./NavbarAdmin";
export default function PageContent(props) {
  return (
    <PageContentStyle>
      <main className="page-content">
        <NavbarAdmin role={props.role}/>
        {props.children}
      </main>
    </PageContentStyle>
  );
}
