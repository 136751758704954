import React, { useState, useRef, useContext } from "react";
import { useEffect } from "react";
import { Text } from "../../../../../../assets/css/components/admin/marketplace-to-marketplace/modals/ModalSelectStoreAndSection";
import FormProduct from "../../../../../../assets/css/components/admin/marketplace-to-marketplace/products/FormProduct";
import ListOfProductsToSetParams from "./ListProductsToSetParams";
import { GlobalContext } from "../../../../../../contexts/GlobalContext";

export default function SetParamProdToPost({
  isLoad,
  setIsLoad,
  paramsToSend,
  setParamsToSend,
  listinType,
  setListinType,
  category
}) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [urlImgPlus, setUrlImgPlus] = useState("");
  const [imgPlusArr, setImgPlusArr] = useState([]);
  const urlPlusInput = useRef(null);
  const globalContext = useContext(GlobalContext);
  const [blockField, setBlockField] = useState(true);
  const cuotasCashea = [3,6,9,12,15] 

  useEffect(() => {
    console.log("parametros a enviar (paramsToSend): ", paramsToSend);
    if (globalContext.pluginSearchForpost === "anicam") {
      setBlockField(true);
    } else {
      setBlockField(false);
    }
  }, [globalContext.pluginSearchForpost]);

  
  useEffect(() => {

    if(paramsToSend.length === 0) globalContext.setModalPostProduct(false)

  }, [paramsToSend]);

  const sendToFormData = async (v, currentPosition) => {
    setCurrentIndex(currentPosition);
  };

  const titleFunc = (v) => {
    console.log(paramsToSend)
    if(paramsToSend.length > 0){
      let copyArray = [...paramsToSend];
      copyArray[currentIndex].Titulo = v;
      setParamsToSend(copyArray);
    }
  };

  const brandFunc = (v) => {
    if (blockField) return;
    let copyArray = [...paramsToSend];
    copyArray[currentIndex].Marca = v;
    setParamsToSend(copyArray);
  };
  const modelFunc = (v) => {
    if (blockField) return;
    let copyArray = [...paramsToSend];
    copyArray[currentIndex].sku = v;
    setParamsToSend(copyArray);
  };

  const bodyFunc = (v) => {
    let copyArray = [...paramsToSend];
    copyArray[currentIndex].body = v;
    setParamsToSend(copyArray);
  };
  

  console.log("validamos el index", currentIndex, paramsToSend);
 
  return (
    <div style={{ width: "100%", height: "auto" }}>
      <ListOfProductsToSetParams sendToFormData={sendToFormData} paramsToSend={paramsToSend} setParamsToSend={setParamsToSend}  />
      {paramsToSend.length > 0 && (
        <FormProduct.ContForms>
          <FormProduct.Contform>
            <FormProduct.HeaderContForm>
              <Text size='13pt' fw='500' cl='#FFFFFF'>
                Parametros Generales
              </Text>
            </FormProduct.HeaderContForm>
            <FormProduct.BodyContForm>
              <FormProduct.ContInputAndLabel w='340px' mr='5px'>
                <Text size='12pt' fw='500'>
                  Tipo de cuota
                  <sup>*</sup>
                </Text>
                <FormProduct.Select
                  onChange={(e) => {
                    setListinType(e.target.value)
                  }}
                >
                  <option value=''>Seleccionar</option>
                  {cuotasCashea.map((cuota)=>(
                    <option key={cuota} value={cuota}>{cuota} meses</option>
                  ))}
                </FormProduct.Select>
              </FormProduct.ContInputAndLabel>
            </FormProduct.BodyContForm>
          </FormProduct.Contform>

          <FormProduct.Contform>
            <FormProduct.HeaderContForm>
              <Text size='13pt' fw='500' cl='#FFFFFF'>
                Llene los parametros para el producto a publicar
              </Text>
            </FormProduct.HeaderContForm>
            <FormProduct.BodyContForm>
              <FormProduct.ContInputAndLabel mr='5px'>
                <Text size='12pt' fw='500'>
                  Titulo
                  <sup>*</sup>
                </Text>
                <FormProduct.Input
                  placeholder='Titulo de la Publicacion'
                  value={paramsToSend[currentIndex]?.Titulo}
                  onChange={(e) => titleFunc(e.target.value)}
                  maxLength={60}
                />
              </FormProduct.ContInputAndLabel>

              <FormProduct.ContInputAndLabel w='340px'>
                <Text size='12pt' fw='500'>
                  Precio
                  <sup>*</sup>
                </Text>
                <FormProduct.Input
                  disabled={true}
                  placeholder='Precio para la publicacion'
                  value={paramsToSend[currentIndex]?.Precio}
                />
              </FormProduct.ContInputAndLabel>

              <FormProduct.ContInputAndLabel w='340px'>
                <Text size='12pt' fw='500'>
                  Marca de Producto
                  <sup>*</sup>
                </Text>
                <FormProduct.Input
                  disabled={blockField}xx
                  placeholder='La marca para el producto'
                  value={paramsToSend[currentIndex]?.Marca}
                  onChange={(e) => brandFunc(e.target.value)}
                />
              </FormProduct.ContInputAndLabel>

              <FormProduct.ContInputAndLabel w='340px'>
                <Text size='12pt' fw='500'>
                  Referencia interna
                  <sup>*</sup>
                </Text>
                <FormProduct.Input
                  disabled={blockField}
                  placeholder='Referencia interna'
                  value={paramsToSend[currentIndex].sku}
                  onChange={(e) => modelFunc(e.target.value)}
                />
              </FormProduct.ContInputAndLabel>

              <FormProduct.ContInputAndLabel w='340px'>
                <Text size='12pt' fw='500'>
                  Categoría
                  <sup>*</sup>
                </Text>
                <FormProduct.Input
                  disabled={true}
                  placeholder='Categoría'
                  value={category.category}
                  onChange={(e) => modelFunc(e.target.value)}
                />
              </FormProduct.ContInputAndLabel>

              <FormProduct.ContInputAndLabel w='340px'>
                <Text size='12pt' fw='500'> 
                  Sub categoría
                  <sup>*</sup>
                </Text>
                <FormProduct.Input
                  disabled={true}
                  placeholder='Sub categoría'
                  value={category.subCategory}
                  onChange={(e) => modelFunc(e.target.value)}
                />
              </FormProduct.ContInputAndLabel>

              <FormProduct.ContInputAndLabel>
                <Text size='12pt' fw='500'>
                  Descripcion del Producto
                </Text>
                <FormProduct.TextArea
                  placeholder='Descripcion para el producto'
                  value={paramsToSend[currentIndex]?.body}
                  onChange={(e) => bodyFunc(e.target.value)}
                />
              </FormProduct.ContInputAndLabel>
            </FormProduct.BodyContForm>
          </FormProduct.Contform>
        </FormProduct.ContForms>
      )}
    </div>
  );
}
