import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useContext, useEffect, useState } from "react"
import LinksAnotherMenuCss from "../../../assets/css/components/admin/menus/LinksAnotherMenuCss";
import { GlobalContext } from "../../../contexts/GlobalContext";
import Routes from "../../../Routes";
import activeRouteV from "../../../assets/js/ActiveRoute";
import { Link } from "react-router-dom";
import {
    third,
    userRole
} from "../../../assets/js/SessionStorageNames";
export default function LinksAnotherMenu() {
    const r = Routes;
    const globalContext = useContext(GlobalContext);
    const [isThirdPartyUser, setIsThirdPartyUser] = useState(globalContext.third);
    const v_userRole = localStorage.getItem(userRole);

    const toggleSidebar = (v) => {
        globalContext.setStatusMenu(v)
    }

    useEffect(() => {
        if(globalContext.third===null) {
            if(localStorage.getItem(third)){
                globalContext.setThird(JSON.parse(localStorage.getItem(third)));
            } else {
                globalContext.setThird(false);
            }
        }
        setIsThirdPartyUser(globalContext.third);
    },[globalContext.third])

    useEffect(() => {
        if (!v_userRole || v_userRole === undefined) {
            globalContext.setVerifyLogin(true);
        } else {
            globalContext.setVerifyLogin(false);
        }
    }, [v_userRole]);

    return (
        <LinksAnotherMenuCss.cont>
            {
                r.map((l, i) => {
                    if (l.isFather) {
                        if (l.rolAccess.includes(v_userRole) || (isThirdPartyUser && ['Rangos Ml Amazon', "Plugins"].includes(l.name))) {
                            if(!l.enterpriseBlock.find((v) => v === globalContext.currentEnterpriseId)) {
                                if(isThirdPartyUser) {
                                    if(l.third) {
                                        return <Link key={i} to={l.layout + l.path} style={{ color: `${activeRouteV(l.layout + l.path) === true ? '#FFFFFF' : '#707070'}` }}>
                                            <LinksAnotherMenuCss.LinkSidebar active={activeRouteV(l.layout + l.path)} onClick={() => { toggleSidebar(false) }}>
                                                <span>
                                                    <FontAwesomeIcon icon={l.icon} />
                                                </span>
                                                <p >{'Rangos Ml Amazon' === l.name ? 'Rangos de Precios' : l.name }</p>
                                            </LinksAnotherMenuCss.LinkSidebar>
                                        </Link>
                                    }
                                    return null;
                                } else {
                                    return (
                                        <Link key={i} to={l.layout + l.path} style={{ color: `${activeRouteV(l.layout + l.path) === true ? '#FFFFFF' : '#707070'}` }}>
                                            <LinksAnotherMenuCss.LinkSidebar active={activeRouteV(l.layout + l.path)} onClick={() => { toggleSidebar(false) }}>
                                                <span>
                                                    <FontAwesomeIcon icon={l.icon} />
                                                </span>
                                                <p >{l.name}</p>
                                            </LinksAnotherMenuCss.LinkSidebar>
                                        </Link>
                                    )
                                }
                            } 
                        }
                    }
                })
            }
        </LinksAnotherMenuCss.cont>
    )
}
