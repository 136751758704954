export const listMerchantName = [
  { merchant: '212 GLOBAL' },
  { merchant: '212 Global Eléctronicos' },
  { merchant: '212 Global Perfumes' },    
  { merchant: 'Acquabela' },
  { merchant: 'Adornos ILU' },
  { merchant: 'Aladino' },
  { merchant: 'Bentysandy' },
  { merchant: 'BKS' },
  { merchant: 'BM' },
  { merchant: 'Bosch' },
  { merchant: 'BS' },
  { merchant: 'Cabel' },
  { merchant: 'CBX' },
  { merchant: 'Cisa' },
  { merchant: 'Coleman' },
  { merchant: 'Color Twist' },
  { merchant: 'Coven' },
  { merchant: 'Dispufil' },
  { merchant: 'Donsen' },
  { merchant: 'Ducarsil' },
  { merchant: 'Ecology' },
  { merchant: 'Edge' },
  { merchant: 'Edil' },
  { merchant: 'Eureka' },
  { merchant: 'Federal signal' },
  { merchant: 'Femsa' },
  { merchant: 'Firestone' },
  { merchant: 'Frigilux (212 Global)' },
  { merchant: 'Fulgor (212 Global)' },
  { merchant: 'General Electric' },
  { merchant: 'Hammer Automotriz' },
  { merchant: 'Hammer Electronic' },
  { merchant: 'Hawaiian Tropic' },
  { merchant: 'HJM' },
  { merchant: 'Huggies' },
  { merchant: 'Igoto' },
  { merchant: 'Ipa' },
  { merchant: 'Ipanema' },
  { merchant: 'K6' },
  { merchant: 'K-pop' },
  { merchant: 'Krip' },
  { merchant: 'La palma real' },
  { merchant: 'Mabe' },
  { merchant: 'Magefesa' },
  { merchant: 'Mageflón' },
  { merchant: 'Michelin' },
  { merchant: 'Momentop' },
  { merchant: 'Motul' },
  { merchant: 'New Balance' },
  { merchant: 'Nexen' },
  { merchant: 'Nivea' },
  { merchant: 'Nucita' },
  { merchant: 'Ondaflex 212 Global' },
  { merchant: 'Oster' },
  { merchant: 'Panini' },
  { merchant: 'Pickens' },
  { merchant: 'Pinta-T' },
  { merchant: 'PintuGama' },
  { merchant: 'Plt' },
  { merchant: 'Printon' },
  { merchant: 'Regal' },
  { merchant: 'Rs' },
  { merchant: 'Senfineco' },
  { merchant: 'Shaft' },
  { merchant: 'Shakespeare' },
  { merchant: 'Skil' },
  { merchant: 'Sony' },
  { merchant: 'Tamanaco' },
  { merchant: 'Tolsen' },
  { merchant: 'TOPPS' },
  { merchant: 'Ultralub' },
  { merchant: 'Venceramica' },
  { merchant: 'Visalock' },
  { merchant: 'VP' },
  { merchant: 'Wellmax' },
  { merchant: 'Yamaha' },
  { merchant: 'Zoe' },
  { merchant: 'Zowa' }
]

